<template>
  <div class="pb-5">
    <div class="d-flex align-items-center mb-4">
      <div class="col-md-6">
        <div>
          <a
            @click="$router.back()"
            class="text-dark d-flex align-items-center cursor-pointer"
          >
            <span class="d-flex align-items-center">
              <vs-icon icon="chevron_left"></vs-icon>
            </span>
            <span class="text-underline">Back</span>
          </a>
        </div>
      </div>

      <div class="col-md-6">
        <div class="text-right">
          <router-link
            :to="'/blog/edit/' + blog.slug"
            class="btn btn-primary btn-site-outline px-3"
          >
            Edit Blog
          </router-link>

          <button
            @click="removeBlog()"
            class="btn btn-primary btn-site-outline px-3"
          >
            Remove Blog
          </button>
        </div>
      </div>
    </div>
    <SectionLoading v-if="loading" text="Blog item loading..." />

    <div class="row justify-content-center my-5" v-else>
      <div class="col-md-8">
        <h1 class="blog-title mb-3">{{ blog.title }}</h1>

        <div>
          <vs-chip
            v-for="tag in blog.tags"
            :key="tag"
            class="mr-2 mb-2"
            color="primary"
          >
            {{ tag }}
          </vs-chip>
        </div>

        <img
          :alt="blog.title"
          :src="blog.avatar"
          class="img-fluid my-3 w-full"
        />

        <div v-html="blog.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionLoading from "@/components/SectionLoading.vue";
export default {
  components: {
    SectionLoading,
  },
  data() {
    return {
      loading: false,
      blog: {},
    };
  },
  methods: {
    getBlogBySlug() {
      this.loading = true;
      const slug = this.$route.params.id;

      let fetch = {
        path: `/admin/blog/${slug}`,
      };
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          this.blog = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Blog Data",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "Blog Data",
              text: "Unable to get blog data",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
    removeBlog() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Remove Blog",
        text: "Are you sure you want to remove this blog?",
        accept: this.removeConfirmed,
      });
    },
    removeConfirmed() {
      this.$vs.loading();
      let fetch = {
        path: `admin/blog/delete/${this.$route.params.id}`,
        data: {},
      };
      this.$store
        .dispatch("deleteRequest", fetch)
        .then((resp) => {
          this.$toast.success(
            "Blog Delete",
            "Blog has been deleted successfully",
            this.$toastPosition
          );

          this.$vs.loading.close();
          setTimeout(() => {
            this.$router.push("/blog");
          }, 1000);
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Unable to delete blog",
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Something went wrong",
              "Unable to delete blog",
              this.$toastPosition
            );
          }
        });
    },
  },
  created() {
    this.getBlogBySlug();
  },
};
</script>

<style lang="scss" scoped>
.blog-title {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: #212529;
}
</style>
